import {
  create as createCredentials,
  get as getCredentials,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill'
import buildVerifyCredentialsHeader from 'app/assets/javascripts/modules/build-verify-credentials-header'
import fetch from 'modules/request/fetch'
import getCsrfToken from 'modules/request/get-csrf-token'
import * as _ from 'modules/util'
import * as R from 'ramda'

export const createCredential = async verificationCredentials => {
  const verificationHeaders = buildVerifyCredentialsHeader(verificationCredentials)
  const filteredVerificationHeaders = R.filter(R.isNotNil, verificationHeaders)

  const headers = {
    ...{ 'X-CSRF-Token': getCsrfToken() },
    ...filteredVerificationHeaders,
  }
  const challengeResponse = await fetch({
    method: 'POST',
    url: '/v1/passkeys/create_challenge',
    headers,
  })

  const credentialCreationOptions = parseCreationOptionsFromJSON({
    publicKey: challengeResponse.body,
  })
  return createCredentials(credentialCreationOptions)
}

export const verifyCredential = async () => {
  const challengeResponse = await fetch({
    method: 'POST',
    url: '/v1/passkeys/reauthenticate_challenge',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
    },
  })
  const getCredentialOptions = parseRequestOptionsFromJSON({ publicKey: challengeResponse.body })
  return getCredentials(getCredentialOptions)
}

export const fetchSigninCredential = async ({ mediation, signal } = {}) => {
  try {
    const challengeResponse = await fetch({
      method: 'POST',
      url: '/users/sign_in/new_challenge',
      headers: {
        'X-CSRF-Token': getCsrfToken(),
      },
    })

    const requestData = _.thru(
      {
        publicKey: challengeResponse.body,
        mediation,
        signal,
      },
      R.reject(R.isNil)
    )

    const getCredentialOptions = parseRequestOptionsFromJSON(requestData)
    return getCredentials(getCredentialOptions)
  } catch (error) {
    if (mediation !== 'conditional') {
      throw error
    }
  }
}

export const isConditionalMediationAvailable = async () =>
  window.PublicKeyCredential?.isConditionalMediationAvailable()

export const fetchConditionalSigninCredential = async ({ signal }) => {
  if (!(await isConditionalMediationAvailable())) {
    return
  }
  return fetchSigninCredential({ mediation: 'conditional', signal })
}
