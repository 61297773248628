import * as S from 'apps/cliniko/style'
import * as F from 'modules/form'

const CheckboxAndSubmit = ({ state, ...props }) => (
  <div css={bottomFormStyles}>
    <F.Checkbox
      css={checkBoxStyles}
      legacyStyle
      name="user.rememberMe"
      defaultChecked={state.current.rememberMe}
      onChange={event => {
        // eslint-disable-next-line react-compiler/react-compiler
        state.current.rememberMe = event.target.checked && event.target.value
      }}
      {...props}>
      Keep me logged in on this device
    </F.Checkbox>
    <F.SubmitButton action compact legacyStyle>
      Log in
    </F.SubmitButton>
  </div>
)

const bottomFormStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [S.legacy.media.medium]: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: S.rem(145),
  },
}

const checkBoxStyles = {
  span: {
    color: 'white',
  },

  '.checkbox-input:disabled + span': {
    color: 'white',
  },

  [S.legacy.media.belowMedium]: {
    marginBottom: S.rem(20),
  },
}

export default CheckboxAndSubmit
